import TYPES from '@/types';

import Vue from 'vue';

import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';

// Domain
import AccessMoreStrategiesState from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class InvestmentStrategyInitialBalanceViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  readonly i18n_namespace = 'components.allianz-dashboard.initial-balance';

  readonly i18n_form_errors_namespace = 'utils.form-rules';

  readonly view: Vue;

  is_valid_form = false;

  initial_balance_slider = 500;

  min_contribution = 1000;

  initial_balance_min_value = 0;

  initial_balance_max_value = 0;

  remaining_balance = 0;

  show_balance_note = false;

  state: AccessMoreStrategiesState;

  public constructor(view: Vue) {
    this.view = view;
    this.state = this.manager_service.getAccessMoreStrategiesState();
    this.initial_balance_max_value = this.getMaximumAmountAllowedPerStrategy(this.state);
    this.show_balance_note = this.validateIfShowBalanceNote(this.state);
    this.updateAvailableBalance();
  }

  inputs_rules = {
    initial_balance: [
      requiredRule,
      (value: string) => (
        (this.state.define_contribution_later || parseFloat(value.replace(/[^0-9.]/g, '')) <= this.initial_balance_max_value)
        || this.translate(
          'maximum_error',
          { value: `${currencyFormat(this.initial_balance_max_value)} MXN` },
          this.i18n_form_errors_namespace,
        )
      ),
      (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$1000.00 MXN', this.min_contribution),
    ],
  }

  translate = (message: string, values?: Values, namespace = this.i18n_namespace) => this.translator.translate(`${namespace}.${message}`, values);

  get can_continue() {
    return this.is_valid_form;
  }

  get available_balance_formatted() {
    return currencyFormat(this.state.available_balance);
  }

  get remaining_balance_formatted() {
    return currencyFormat(this.remaining_balance);
  }

  get show_warning_maximum() {
    return parseFloat(this.state.initial_balance
      .replace(/[^0-9.]/g, '')) === this.initial_balance_max_value;
  }

  updateAvailableBalance = () => {
    const parsed_initial_balance = parseFloat(
      this.state.initial_balance.replace(/[^0-9.]/g, ''),
    );
    this.initial_balance_slider = Math.floor(parsed_initial_balance);
    this.remaining_balance = this.state.available_balance - parsed_initial_balance;
  }

  updateInitialBalance = () => {
    this.state.initial_balance = this.initial_balance_slider.toString();
    this.updateAvailableBalance();
  }

  getMaximumAmountAllowedPerStrategy = (state: AccessMoreStrategiesState): number => {
    const rounded_initial_balance = Math.floor(state.available_balance);
    const minimum = state.strategy.label === 'SWSRFP' ? 0 : 500;
    const available = rounded_initial_balance - minimum;
    return available >= minimum ? available : 0;
  }

  validateIfShowBalanceNote = (state: AccessMoreStrategiesState): boolean => (
    state.strategy.label !== 'SWSRFP'
  );

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    const initial_balance_without_format = this.state.initial_balance.replace(/[^0-9.]/g, '');
    this.view.$emit('nextStep', { initial_balance: initial_balance_without_format });
  }
}
